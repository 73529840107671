import React from 'react';

import { FormField, TextInput, Button, Heading, Text, Box, Select } from 'grommet';
import styled from 'styled-components';

import {
    ALL_STYLES,
    STYLE_1,
    STYLE_2,
    STYLE_3,
    STYLE_4,
    STYLE_5,
    STYLE_6,
    STYLE_7,
    STYLE_8,
    STYLE_9,
    STYLE_10,
} from '../quote';
import { Alert, StyledStep, StyledAction, StyledOption, StyledForm, StyledGrid } from '../styles';
import roof1 from '../../images/styles/lean-to.svg';
import roof2 from '../../images/styles/edwardian.svg';
import roof3 from '../../images/styles/double-ended-edwardian.svg';
import roof4 from '../../images/styles/gable-end.svg';
import roof5 from '../../images/styles/victorian.svg';
import roof6 from '../../images/styles/gull-wing.svg';
import roof7 from '../../images/styles/p-shape-left.svg';
import roof8 from '../../images/styles/p-shape-right.svg';
import roof9 from '../../images/styles/l-shape-left.svg';
import roof10 from '../../images/styles/l-shape-right.svg';
import builder from '../../images/builder.png';

const StyledFormItems = styled(Box)`
    > * + * {
        margin-top: 16px;
    }
`;
const roofs = [roof1, roof2, roof3, roof4, roof5, roof6, roof7, roof8, roof9, roof10];

const Step1 = ({ fullForm, nextStep, prevStep, handleChange, currentStyle }) => (
    //  const active = ALL_STYLES[currentStyle];
    <>
        <StyledStep>
            <Heading level="2">Please enter the size of your {currentStyle} conservatory</Heading>
            <StyledGrid>
                <div>
                    {ALL_STYLES.map((style, i) => {
                        if (style !== currentStyle) {
                            return null;
                        }
                        return (
                            <StyledOption key={style} type="button" value={style}>
                                <img src={roofs[i]} alt={style} />
                            </StyledOption>
                        );
                    })}
                </div>
                <StyledForm gap="medium">
                    <StyledFormItems direction="column">
                        {(() => {
                            switch (currentStyle) {
                                case STYLE_7:
                                case STYLE_8:
                                case STYLE_9:
                                case STYLE_10:
                                    return (
                                        <>
                                            <TextInput
                                                name="sizeA"
                                                type="number"
                                                placeholder="Size A (Meters)"
                                                onChange={handleChange('sizeA')}
                                                value={fullForm.sizeA}
                                            />

                                            <TextInput
                                                name="Size B"
                                                type="number"
                                                placeholder="Size B (Meters)"
                                                onChange={handleChange('sizeB')}
                                                value={fullForm.sizeB}
                                            />

                                            <TextInput
                                                name="sizeC"
                                                type="number"
                                                placeholder="Size C (Meters)"
                                                onChange={handleChange('sizeC')}
                                                value={fullForm.sizeC}
                                            />

                                            <TextInput
                                                name="sizeD"
                                                type="number"
                                                placeholder="Size D (Meters)"
                                                onChange={handleChange('sizeD')}
                                                value={fullForm.sizeD}
                                            />
                                        </>
                                    );
                                default:
                                    return (
                                        <>
                                            <TextInput
                                                name="sizeA"
                                                type="number"
                                                placeholder="Size A (Meters)"
                                                onChange={handleChange('sizeA')}
                                                value={fullForm.sizeA}
                                            />

                                            <TextInput
                                                name="sizeB"
                                                type="number"
                                                placeholder="Size B (Meters)"
                                                onChange={handleChange('sizeB')}
                                                value={fullForm.sizeB}
                                            />
                                        </>
                                    );
                            }
                        })()}

                        <TextInput
                            name="numberPanels"
                            type="number"
                            placeholder="Number of roof panels?"
                            onChange={handleChange('numberPanels')}
                            value={fullForm.numberPanels}
                        />

                        <Select
                            placeholder="Existing glazing type"
                            name="glazingType"
                            options={['Standard polycarbonate', 'Ultralite/Everlite', 'Glass']}
                            value={fullForm.glazingType}
                            onChange={({ option }) => handleChange('glazingType')(option)}
                        />
                    </StyledFormItems>
                </StyledForm>
            </StyledGrid>
        </StyledStep>
        <StyledAction>
            <div className="action-content">
                >
                <div className="action-inputs">
                    {fullForm.sizeA && fullForm.sizeB ? (
                        <Alert>Click next to continue</Alert>
                    ) : (
                        <Alert>Enter sizes to continue</Alert>
                    )}
                </div>
                <div className="action-actions">
                    <Button
                        type="button"
                        primary
                        label={<Text color="white">Previous</Text>}
                        color="accent-1"
                        onClick={prevStep}
                    />
                    <Button
                        type="button"
                        primary
                        label={<Text color="white">Next</Text>}
                        color="brand"
                        onClick={nextStep}
                        disabled={!(fullForm.sizeA && fullForm.sizeB)}
                    />
                </div>
            </div>
        </StyledAction>
    </>
);

export default Step1;
