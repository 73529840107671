import React from 'react';

import { Heading, Button, Text } from 'grommet';
import { Alert, StyledStep, StyledAction, StyledOption, StyledOptions } from '../styles';

import { ALL_STYLES } from '../quote';

import roof1 from '../../images/styles/lean-to.svg';
import roof2 from '../../images/styles/edwardian.svg';
import roof3 from '../../images/styles/double-ended-edwardian.svg';
import roof4 from '../../images/styles/gable-end.svg';
import roof5 from '../../images/styles/victorian.svg';
import roof6 from '../../images/styles/gull-wing.svg';
import roof7 from '../../images/styles/p-shape-left.svg';
import roof8 from '../../images/styles/p-shape-right.svg';
import roof9 from '../../images/styles/l-shape-left.svg';
import roof10 from '../../images/styles/l-shape-right.svg';
import builder from '../../images/builder.png';

const roofs = [roof1, roof2, roof3, roof4, roof5, roof6, roof7, roof8, roof9, roof10];

const Step1 = ({ nextStep, prevStep, handleChange, currentStyle }) => {
    const handleSelect = (e) => {
        handleChange('currentStyle')(e);
        nextStep();
    };
    return (
        <>
            <StyledStep>
                <Heading level={2}>Select the roof that best matches yours</Heading>
                <StyledOptions>
                    {ALL_STYLES.map((style, i) => (
                        <StyledOption
                            key={style}
                            type="button"
                            onClick={(e) => handleSelect(e)}
                            value={style}
                            className={currentStyle === style ? 'is-active' : undefined}
                        >
                            <img src={roofs[i]} alt={style} />
                            <span className="label">{style}</span>
                        </StyledOption>
                    ))}
                </StyledOptions>
            </StyledStep>
            <StyledAction>
                <div className="action-content">
                    <div className="action-inputs">
                        {!currentStyle && <Alert>Please select a roof style to continue</Alert>}
                        {currentStyle && <Alert>Click next to continue</Alert>}
                    </div>
                    <div className="action-actions">
                        {/* <Button
                        type="button"
                        color="accent-1"
                        onClick={prevStep}
                        primary
                        label={<Text color="white">Previous</Text>}
                    /> */}
                        <Button
                            type="button"
                            color="brand"
                            onClick={nextStep}
                            disabled={!currentStyle}
                            primary
                            label={<Text color="white">Next</Text>}
                        />
                    </div>
                </div>
            </StyledAction>
        </>
    );
};

export default Step1;
